import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "ok-text": "Save", "title": "Edit Price", "after-close": _vm.afterModalClose, "width": 520 }, on: { "ok": _vm.submit }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.submit);
      } } }, [_vm._v("Save")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Item Code", "placeholder": "Type Item Code", "form-item": "", "rules": "required", "value": _vm.entity.itemCode, "span": 12 }, on: { "change": _vm.onItemCodeChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "data-source": _vm.countries, "source": "countryId", "source-label": "country", "label": "Country", "placeholder": "Select Country", "disabled": true, "span": 12, "value": _vm.entity.countryID, "form-item": "" }, on: { "change": _vm.onCountryChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "label": "Currency", "reference": "web-analytics.common.ppvfw-currencies", "placeholder": "Select Currency", "source": "currency", "value": _vm.entity.currency, "rules": "required", "span": 12, "form-item": "" }, on: { "change": _vm.onCurrencyChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("text-input", { attrs: { "label": "Price", "placeholder": "Type Price", "form-item": "", "rules": _vm.regex10digits5frac, "max-length": 16, "span": 12, "value": _vm.entity.price }, on: { "change": _vm.onPriceChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "reference": "web-analytics.common.ppvfw-fin-periods", "rules": "required", "label": "Start Date", "placeholder": "Select Start Date", "source": "intStartDate", "source-label": "finPeriodChar", "span": 12, "value": _vm.entity.intStartDate }, on: { "change": _vm.onStartDateChange } })], 1), _c("a-col", { attrs: { "span": 12 } }, [_c("select-input", { attrs: { "reference": "web-analytics.common.ppvfw-fin-periods", "rules": "required", "label": "End Date", "placeholder": "Select End Date", "source": "intEndDate", "source-label": "finPeriodChar", "span": 12, "value": _vm.entity.intEndDate }, on: { "change": _vm.onEndDateChange } })], 1)], 1)], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "EditPrices",
  inject: ["crud"],
  mixins: [MyUtil],
  data() {
    return {
      apiUrl: apiUrl$1,
      visible: false,
      isLoading: false,
      country: "",
      itemCode: "",
      currnecy: "",
      price: "",
      startDate: "",
      endDate: ""
    };
  },
  computed: {
    entity() {
      return this.crud.getEntity();
    }
  },
  created() {
    this.getCountries();
  },
  mounted() {
    this.visible = true;
  },
  methods: {
    async submit() {
      this.isLoading = true;
      await this.axios.put(`${this.apiUrl}/web-analytics/input-prices/${this.country ? this.country : this.entity.countryID}`, {
        CountryID: this.country ? this.country : this.entity.countryID,
        Currency: this.currency ? this.currency : this.entity.currency,
        ItemCode: this.itemCode ? this.itemCode : this.entity.itemCode,
        IntStartDate: this.startDate ? this.startDate : this.entity.intStartDate,
        IntEndDate: this.endDate ? this.endDate : this.entity.intEndDate,
        Price: this.price ? this.price : this.entity.price,
        UpdateType: "update"
      }).then(() => {
        this.isLoading = false;
        this.visible = false;
        this.crud.fetchList();
        this.$notification["success"]({
          message: "Item Updated!"
        });
      });
    },
    afterModalClose() {
      this.$router.push("/web-analytics/prices");
    },
    onCancel() {
      this.visible = false;
    },
    parseIntStartDate(value) {
      if (!value) {
        return "";
      }
      return this.$moment(`${value.intStartDate}`).format("YYYY/MM/DD");
    },
    parseIntEndDate(value) {
      if (!value) {
        return "";
      }
      return this.$moment(`${value.intEndDate}`).format("YYYY/MM/DD");
    },
    onCountryChange(value) {
      this.country = value;
    },
    onItemCodeChange(value) {
      this.itemCode = value;
    },
    onCurrencyChange(value) {
      this.currency = value;
    },
    onPriceChange(value) {
      this.price = value;
    },
    onStartDateChange(value) {
      this.startDate = value;
    },
    onEndDateChange(value) {
      this.endDate = value;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditPrices = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "web-analytics.common.countries", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-currencies", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-facilities", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-gl-codes", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-fin-periods", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.input-prices", "resource-id-name": "costID", "api-url": _vm.apiUrl, "redirect-route": "/web-analytics/prices" } }, [_c("edit-prices")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditPrices
  },
  data() {
    return {
      EditPrices,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
